<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import { pluralize } from '/~/utils/format'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import CashbackPanel from '/~/components/cashback/cashback-panel.vue'
import CheckoutCoupons from '/~/components/checkout/checkout-coupons.vue'
import PointsPanel from '/~/components/points/points-panel.vue'
import { useQuickBuyCheckout } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import CheckoutHeaderMobile from '/~/views/quick-buy/components/header/quick-buy-header.mobile.vue'
import SummaryTotal from '/~/views/quick-buy/components/summary/mobile/summary-total.vue'
import CheckoutAddresses from '../../components/addresses/quick-buy-addresses.vue'
import CheckoutPayments from '../../components/payments/quick-buy-payments.vue'
import CheckoutSummary from '../../components/summary/quick-buy-summary.mobile.vue'

const router = useRouter()
const { payment, cashbackRebateTotal } = useQuickBuyCheckout()
const { isUserAddressEnabled } = useProvider()
const { isPurchaseOrderPoints } = usePoints()

const count = computed(() => {
  const itemsCount = payment.checkoutItemsCount

  return `${itemsCount} ${pluralize(itemsCount, 'item')}`
})
</script>

<template>
  <div class="space-y-6">
    <checkout-addresses v-if="isUserAddressEnabled" v-bind="$attrs" />
    <checkout-payments v-bind="$attrs" />
    <checkout-coupons v-bind="$attrs" :flow-type="FlowType.quickBuy" />

    <base-collapse
      v-if="count"
      show-icon
      :autoscroll="false"
      icon="v2/custom/chevron-down-outline"
      :icon-size="24"
    >
      <template #trigger>
        <span class="block font-bold">View Summary ({{ count }})</span>
      </template>

      <div class="mt-4">
        <checkout-summary />
      </div>
    </base-collapse>

    <summary-total class="pb-0" />

    <div>
      <points-panel
        v-if="isPurchaseOrderPoints"
        :total="payment.pointsEarned"
        class="-mx-5 sm:mx-0"
      />
      <cashback-panel :total="cashbackRebateTotal" class="-mx-5 sm:mx-0" />
    </div>

    <checkout-header-mobile @back="router.push({ name: 'home' })">
      Express checkout
    </checkout-header-mobile>
  </div>
</template>
